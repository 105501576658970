import React, { useEffect, useState } from "react";
import "./BookingList.css";
import axios from "axios";
import { FaTrash } from "react-icons/fa";

function BookingList() {
  const [book, setBooking] = useState([]);

  useEffect(() => {
    EnquiryListapi();
  }, []);

  const EnquiryListapi = () => {
    axios({
      method: "POST",
      url: "https://lunarsenterprises.com:6020/aqualillies/list/bookings",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result) {
          setBooking(response.data.list);
        } else {
          console.log(response.data.message, "wronganswer");
        }
      })
      .catch((err) => {
        console.log(err, "error in booking form");
      });
  };

  const Deleteapi = (id) => {
    axios({
      method: "POST",
      url: "https://lunarsenterprises.com:6020/aqualillies/delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { booking_id: id },
    })
      .then((response) => {
        if (response.data.result) {
          EnquiryListapi();
        } else {
          console.log(response.data.message, "wronganswer");
        }
      })
      .catch((err) => {
        console.log(err, "error in booking form");
      });
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this booking?"
    );
    if (confirmDelete) {
      Deleteapi(id);
    }
  };

  const updateStatus = (id, newStatus) => {
    axios({
      method: "POST",
      url: "https://lunarsenterprises.com:6020/aqualillies/update-booking-status",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { bd_id: id, booking_status: newStatus },
    })
      .then((response) => {
        if (response.data.result) {
          // Update the local state to reflect the new status
          setBooking((prevBookings) =>
            prevBookings.map((booking) =>
              booking.bd_id === id
                ? { ...booking, bd_booking_status: newStatus }
                : booking
            )
          );
        } else {
          console.log(response.data.message, "Status update failed");
        }
      })
      .catch((err) => {
        console.log(err, "Error updating status");
      });
  };

  const handleStatusChange = (id, event) => {
    const newStatus = event.target.value;
    updateStatus(id, newStatus);
  };

  return (
    <>
      <div className="dashboard-header">
        <h4 className="dashboard-heading">Bookings</h4>
      </div>
      <div className="enquiry-table">
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th>Room No</th>
                <th>Name</th>
                <th>Check In</th>
                <th>Check Out</th>
                <th>Mobile</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {book.map((enquiry, index) => (
                <tr key={index}>
                  <td>{enquiry.bd_id}</td>
                  <td>{enquiry.bd_name}</td>
                  <td>
                    {new Date(enquiry.bd_checkin_date).toLocaleDateString()}
                  </td>
                  <td>
                    {new Date(enquiry.bd_checkout_date).toLocaleDateString()}
                  </td>
                  <td>{enquiry.bd_phone}</td>
                  <td>
                    <select
                      value={enquiry.bd_booking_status}
                      onChange={(event) =>
                        handleStatusChange(enquiry.bd_id, event)
                      }
                      className="status-dropdown"
                    >
                      <option value="Booked">Booked</option>
                      <option value="CheckIn">Checked In</option>
                      <option value="CheckOut">Checked Out</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </td>
                  <td>
                    <button
                      className="trash-button"
                      onClick={() => handleDelete(enquiry.bd_id)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default BookingList;
