import React, { useState } from "react";
import axios from "axios";
import "./otp.css";
import { Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Otp() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) nextInput.focus();
    }

    if (value === "" && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      if (prevInput) prevInput.focus();
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateOtp = () => {
    return otp.every((digit) => digit !== "");
  };

  const handleSendOtp = async () => {
    if (!validateEmail()) {
      setError("Please enter a valid email address.");
      return;
    }
    setError("");
    setLoading(true);

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6020/aqualillies/forgot-password",
        {
          email: email,
        }
      );

      console.log("OTP sent successfully:", response.data);

      setSuccess(true);
    } catch (err) {
      setError("Failed to send OTP. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!validateOtp()) {
      setError("Please enter all 4 OTP digits.");
      return;
    }
    setError(""); // Clear any previous errors

    const otpCode = otp.join(""); // Combine OTP digits into one string
    const verificationData = {
      email: email,
      code: otpCode,
    };

    setLoading(true); // Show loading state

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/verification",
        verificationData
      );

      console.log("OTP verification response:", response.data);

      if (response.data.result === true) {
        // OTP verification successful
        setSuccess(true);
        navigate("/changePassword", { state: { email: email } });
      } else {
        // OTP incorrect
        setError("Invalid OTP. Please try again.");
      }
    } catch (err) {
      setError("Failed to verify OTP. Please try again.");
      console.error("Error verifying OTP:", err);
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  return (
    <>
      <div className="otp-box">
        <div className="otp-container">
          <h4 className="reset-heading">RESET YOUR PASSWORD</h4>

          <div className="email">
            <label htmlFor="email" className="email-label">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              className="email-input"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter Your Email ID"
              required
            />
          </div>

          <div className="otp-actions">
            <Button
              className="sent-otp-button"
              onClick={handleSendOtp}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send OTP"}
            </Button>
          </div>

          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">OTP sent successfully!</Alert>}

          <div className="otp-input">
            <label htmlFor="otp" className="otp-label">
              Enter OTP
            </label>
            <div className="otp-boxes">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-${index}`}
                  type="text"
                  maxLength="1"
                  className="otp-field"
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                />
              ))}
            </div>
          </div>

          <Button
            className="verify-otp-button"
            onClick={handleVerifyOtp}
            disabled={loading}
          >
            {loading ? "Verifying..." : "Verify OTP"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default Otp;
