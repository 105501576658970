import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Dashboard.css";
import banner from "../../Assets/banner.jpg";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { FaTrash } from "react-icons/fa";

ChartJS.register(
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [graph, setGraph] = useState([]);
  const [bookList, setBookList] = useState([]);
  const [totalGuests, setTotalGuests] = useState(0);

  const Dashboardapi = () => {
    axios({
      method: "POST",
      url: "https://lunarsenterprises.com:6020/aqualillies/graph-data",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result) {
          setGraph(response.data.list);
        } else {
          console.log(response.data.message, "wronganswer");
        }
      })
      .catch((err) => {
        console.log(err, "error in booking form");
      });
  };

  useEffect(() => {
    if (startDate && endDate) {
      const filtered = graph.filter((order) => {
        const orderDate = new Date(order.bd_checkin_date);
        return orderDate >= startDate && orderDate <= endDate;
      });
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(graph);
    }
  }, [startDate, endDate]);
  useEffect(() => {
    Dashboardapi();
    BookingListapi();
  }, []);
  const graphData = {
    labels: filteredOrders.map((order) =>
      new Date(order.bd_checkin_date).toLocaleDateString()
    ),
    datasets: [
      {
        label: "Number of Guests",
        data: filteredOrders.map((order) => order.bd_total_guests),
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.2,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        pointBorderColor: "#fff",
        pointRadius: 5,
      },
    ],
  };

  const graphOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const BookingListapi = () => {
    axios({
      method: "POST",
      url: "https://lunarsenterprises.com:6020/aqualillies/list/bookings",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result) {
          setBookList(response.data.list);
        } else {
          console.log(response.data.message, "wronganswer");
        }
      })
      .catch((err) => {
        console.log(err, "error in booking form");
      });
  };
  const Deleteapi = (id) => {
    axios({
      method: "POST",
      url: "https://lunarsenterprises.com:6020/aqualillies/delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { booking_id: id },
    })
      .then((response) => {
        if (response.data.result) {
          BookingListapi();
        } else {
          console.log(response.data.message, "wronganswer");
        }
      })
      .catch((err) => {
        console.log(err, "error in booking form");
      });
  };
  useEffect(() => {
    const total = bookList.reduce((acc, curr) => acc + curr.bd_total_guests, 0);
    setTotalGuests(total);
  }, [bookList]);
  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this booking?"
    );
    if (confirmDelete) {
      Deleteapi(id);
    }
  };
  return (
    <div>
      <div className="container">
        <div className="dashboard-first">
          <div className="column">
            <h4 className="dashboard-details">NUMBER OF GUEST</h4>
            <p className="data-value">{totalGuests}</p>
          </div>
          <div className="column">
            <h4 className="dashboard-details">TOTAL BOOKING</h4>
            <p className="data-value">{bookList.length}</p>
          </div>
          <div className="column">
            <h4 className="dashboard-details">REVENUE</h4>
            <p className="data-value">₹0</p>
          </div>
        </div>
        {/* Current Booking Section */}
        <div className="current-booking-section">
          <h3>Current Booking</h3>
          <div className="scrollable-table-container">
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Room No</th>
                  <th>Name</th>
                  <th>Check In</th>
                  <th>Check Out</th>
                  <th>Mobile</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {bookList.map((booking, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{booking.bd_name}</td>
                    <td>
                      {new Date(booking.bd_checkin_date).toLocaleDateString()}
                    </td>
                    <td>
                      {new Date(booking.bd_checkout_date).toLocaleDateString()}
                    </td>
                    <td>{booking.bd_phone}</td>
                    <td>
                      <span
                        className={`status-badge ${booking.bd_booking_status.toLowerCase()}`}
                      >
                        {booking.bd_booking_status}
                      </span>
                    </td>
                    <td>
                      <button
                        className="trash-button"
                        onClick={() => handleDelete(booking.bd_id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="dashboard-analytics">
          <div className="date-picker-container">
            <h4>Select Date Range</h4>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              placeholderText="End Date"
            />
          </div>
          <div className="dashboard-visuals">
            <div className="graph-container">
              <h4>Booking Trend</h4>
              <Line data={graphData} options={graphOptions} />
            </div>

            <div className="booking-list">
              <div className="booking-header">
                <h4>Guest List</h4>
                <a href="/Booking" className="view-all">
                  View All
                </a>
              </div>
              <div className="booking-cards">
                {bookList.map((order) => (
                  <div key={order.bd_id} className="booking-card">
                    <div className="guest-avatar">
                      <img
                        src={`https://i.pravatar.cc/150?u=${order.bd_id}`}
                        alt="Guest"
                      />
                    </div>
                    <div className="guest-info">
                      <h5 className="guest-name">{order.bd_name}</h5>
                      <p className="guest-room">email:{order.bd_email}</p>
                      <p className="guest-room">phone:{order.bd_phone}</p>
                      {/* <p className="guest-room">Room: {order.room}</p> */}
                    </div>
                    <div className="booking-details">
                      <p className="booking-date">
                        {new Date(order.bd_checkin_date).toLocaleDateString(
                          "en-GB",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </p>
                      <p className="booking-time">{order.time}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
