import React, { useEffect, useState } from "react";
import "./EnquiryList.css";
import axios from "axios";

function Enquiry() {
  const [enquiryList, setEnquiryList] = useState([]);
  useEffect(() => {
    EnquiryListapi();
  }, []);
  const enquiries = [
    {
      name: "John Doe",
      email: "john@example.com",
      question: "What is the price of the product?",
    },
    {
      name: "Jane Smith",
      email: "jane@example.com",
      question: "Do you offer international shipping?",
    },
    {
      name: "Sam Wilson",
      email: "sam@example.com",
      question: "Can I customize the order?",
    },
  ];
  const EnquiryListapi = () => {
    axios({
      method: "POST",
      url: "https://lunarsenterprises.com:6020/aqualillies/list/contactus",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result) {
          setEnquiryList(response.data.list);
        } else {
          console.log(response.data.message, "wronganswer");
        }
      })
      .catch((err) => {
        console.log(err, "error in booking form");
      });
  };
  return (
    <>
      <div className="dashboard-header">
        <h4 className="dashboard-heading">ENQUIRIES</h4>
      </div>
      <div className="enquiry-table">
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>phone</th>
                <th>Email</th>
                <th>Enquiry Question</th>
              </tr>
            </thead>
            <tbody>
              {enquiryList.map((enquiry, index) => (
                <tr key={index}>
                  <td>{enquiry.cu_name}</td>
                  <td>{enquiry.cu_phone}</td>
                  <td>{enquiry.cu_email}</td>
                  <td>{enquiry.cu_message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Enquiry;
