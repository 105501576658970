import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Login from "./Components/Login/Login";
import Sidebar from "./Components/SideBar/Sidebar";
import Dashboard from "./Components/Dashboard/Dashboard";
import Navbars from "./Components/Navbar/Navbar";
import EnquiryList from "./Components/Enquiry/EnquiryList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Userlist from "./Components/UserList/Userlist";
import Otp from "./Components/ForgetPassword/Otp";
import Password from "./Components/password/Password";
import BookingList from "./Components/Booking/BookingList";

function App() {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const hideHeaderFooterPaths = ["/", "/ForgetPassword", "/otp", "/password"];

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(loggedInStatus === "true");
  }, []);

  return (
    <div className="d-flex">
      <ToastContainer autoClose={3000} />
      {/* Conditionally render Sidebar */}
      {!hideHeaderFooterPaths.includes(location.pathname) && <Sidebar />}
      <div className="content" style={{ flex: 1 }}>
        {/* Conditionally render Navbar */}
        {!hideHeaderFooterPaths.includes(location.pathname) && <Navbars />}
        <Routes>
          {!isLoggedIn ? (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/password" element={<Password />} />
            </>
          ) : (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/userlist" element={<Userlist />} />
              <Route path="/EnquiryList" element={<EnquiryList />} />
              <Route path="/Booking" element={<BookingList />} />
              <Route path="/" element={<Login />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
}

export default App;
