import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import "./userlist.css";

function User() {
  const [users, setUsers] = useState([]);
  
  // Fetch data when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post('https://lunarsenterprises.com:3000/bhakshanangal/list-user', {
          search: "" // you can customize the search filter here
        });
        
        // Update the state with the user list from the API response
        if (response.data.result) {
          setUsers(response.data.list);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    
    fetchUserData();
  }, []);
  
  return (
    <>
      <div className="dashboard-header">
        <h5 className="dashboard-heading">USER COLLECTION</h5>
      </div>
      <div className="container mt-5">
        <div className="table-responsive">
          <Table className="custom-table" striped hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Joining Date</th>
                <th>Nationality</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user.user_id}>
                  <td>{user.user_name}</td>
                  <td>{user.user_email}</td>
                  <td>{user.user_mobile !== 0 ? user.user_mobile : 'N/A'}</td>
                  <td>{user.user_joining_date ? new Date(user.user_joining_date).toLocaleDateString() : 'N/A'}</td>
                  <td>{user.user_nationality || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default User;
